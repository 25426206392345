import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "sorokins_extended_contract_rfs" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Defender of the Latvian National Team  and  RFS  football club (Riga) Vladislavs Sorokins extended his contract with the club for the one more year.</p>
        </div>
      </div>
    )
  }
}